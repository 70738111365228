import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot } from '@angular/router';
import { map, of } from 'rxjs';
import { ReservationApiUserService } from '../services/api/reservation-api-user.service';
import { TranslateService } from '@ngx-translate/core';

export const ReservationBreadcrumbResolver: ResolveFn<string | undefined> = (
  route: ActivatedRouteSnapshot
) => {
  const translation = inject(TranslateService);
  const reservationId = Number(route.paramMap.get('reservationId'));
  if (reservationId == null || isNaN(reservationId)) {
    return of(undefined);
  }
  return inject(ReservationApiUserService)
    .getReservationById(reservationId, 2)
    .pipe(
      map(
        (reservation) =>
          `${translation.instant('RESERVATIONS.ROOM_RESERVATION.TITLE')} ${reservation?.id}`
      )
    );
};
